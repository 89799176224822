@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  * {
    font-family: 'Rubik', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .img-a {
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .transition-bg {
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -ms-transition: .5s all;
    -o-transition: .5s all;
  }
 

}